// .bgImage {
//   background-image: url("./../../images/london.jpg");
//   height: 100%;
//   background-position: center;
//   background-size: cover;
// }

.bgcontainer {
  background-image: url("./../../images/backdrop-unsplash.webp");
  background-position: center;
  background-size: cover;
}

.bgdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.emptext {
  color: #fff;
  font-size: 2.3rem;
  font-weight: bold;
}
