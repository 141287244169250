* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.react-calendar {
  width: 100% !important;
  height: 300px !important;
}
