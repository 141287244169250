.modal-heading {
  font-size: 1.2rem;
  font-weight: 600;
}

@media (max-width: 575.98px) {
  .disable-from-to {
    font-size: 0.9rem;
  }
  .modal-heading {
    font-size: 1rem;
  }
}
