.emp-banner {
  text-align: center;
  background-color: darkcyan;
  margin-top: 1rem;
}

.emp-banner-text {
  font-size: 2rem;
  padding: 2rem;
}

.thanks-text {
  font-size: 2.5rem;
}

.feedback-height {
  height: 300px;
}

.time-slot-text {
  font-size: 2rem;
  background-color: cornflowerblue;
}

.upcoming-ents-text {
  font-size: 1.5rem;
}
