.option-styles {
  font-size: 1.2rem;
  height: 390px;
}

.option-item {
  cursor: pointer;
  flex-grow: 0;
}

.underlined-text:active,
.underlined-text:hover {
  text-decoration: underline;
}

.select-service-header {
  font-size: 1.5rem;
  font-weight: 500;
}

.fogging {
  background-image: url("./../../images/cleaner.webp");
}

.deep-cleaning {
  background-image: url("./../../images/deepcleaning.webp");
}

.end-of {
  background-image: url("./../../images/end-of-tenancy.jpg");
}

.regular-restaurants {
  background-image: url("./../../images/regular-rest.jpg");
}

.regular-offices {
  background-image: url("./../../images/regular-offices.webp");
}

.other {
  background-image: url("./../../images/other-services.webp");
}
.service-item {
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  transition: transform 0.3s;
  height: 180px;
  cursor: pointer;
}

.backdrop {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  color: #fff;
}

.service-item:hover,
.service-item:active {
  transform: scale(1.05);
}

@media (max-width: 575.98px) {
  .service-item {
    height: 110px;
    width: 95%;
  }
}
