.time-slot-styles {
  font-size: 1rem;
  background-color: cadetblue;
  cursor: pointer;
  color: #fff;
}

.time-slot-styles:hover:not(.highlighted),
.time-slot-styles:active:not(.highlighted) {
  background-color: rgb(124, 179, 181);
}

.highlighted {
  background-color: #483d8b;
}

.disabled {
  background-color: gainsboro !important;
  cursor: default;
}

.disabled-admin {
  background-color: gainsboro !important;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .time-slot-styles {
    font-size: 0.8rem;
  }
}
