.button-container {
  background-color: rgb(51, 118, 208);
  cursor: pointer;
  font-weight: 200;
}

.button-container:hover:not(.disabled),
.button-container:active:not(.disabled) {
  background-color: #538dda;
}

.button-text {
  color: #fff;
  font-size: 1rem;
}

.disabled {
  background-color: gainsboro;
}
