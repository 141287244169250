.time-slot-title {
  font-size: 1.8rem;
  text-align: center;
}

.book-now-btn {
  margin-top: 10rem;
}

.bottom-height-booking {
  height: 600px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .bottom-height-booking {
    height: 360px;
  }
}

.calender-container {
  height: 90%;
}

@media (min-width: 576px) {
  .calender-container {
    height: 100%;
  }
}

.time-slot-container {
  height: 200px;
  overflow: auto;
}

@media (min-width: 576px) {
  .time-slot-container {
    height: 100%;
    overflow: auto;
  }
}

.date-picker-width {
  width: 100%;
}
